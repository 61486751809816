import { useRef } from "react";
import "./App.css";
// @mui
import { Button, Typography, Card, Box, TextField } from "@mui/material";
import { Icon } from "@iconify/react";
import "./App.css";
//oneway

import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

function App() {
  //input fields

  //another way

  // const BigNumber = window.BigNumber;
  // const ethers = window.ethers;
  // const detectEthereumProvider = window.detectEthereumProvider;

  const depositStakeAmount_ref = useRef(null);
  const getInvestorStakeCountAddress_ref = useRef(null);
  const investorCount_ref = useRef(null);
  const signerAddress_ref = useRef(null);
  const tokenContract_ref = useRef(null);
  const stakingContract_ref = useRef(null);
  const timeRemaining_ref = useRef(null);
  const investorIndex_ref = useRef(null);
  const investor_ref = useRef(null);
  const previewInvestorClaimRewardId_ref = useRef(null);
  const previewInvestorClaimRewardAddress_ref = useRef(null);
  const rewardsPreview_ref = useRef(null);
  const claimRewardStakeId_ref = useRef(null);
  const rewardClaimed_ref = useRef(null);
  const previewInvestorWithdrawRewardId_ref = useRef(null);
  const previewInvestorWithdrawRewardAddress_ref = useRef(null);
  const withdrawPreview_ref = useRef(null);
  const burnPreview_ref = useRef(null);
  const withdrawStakeStakeId_ref = useRef(null);
  const withdrawnOutput_ref = useRef(null);
  const stakeCountA_ref = useRef(null);
  const getInvestorStakeStakeId_ref = useRef(null);
  const getInvestorStakeStakeAddress_ref = useRef(null);

  let signer;
  let stakingContract;
  let tokenContract;
  let selectedContract = window.location.href.split("?contract=")[1];
  /*
    90 Days: 65% annual yield (total 16.027% of stake given as reward at maturation)
    https://testnet.bscscan.com/address/0xBD08837459F554A9705547cE5E9b1Ad9137cE26a#code
    2 years: 25% annual yield (total 50% of stake given as reward at maturation)
    https://testnet.bscscan.com/address/0x9b1F2f136694813c47Db76d1fCc762b090aC3dAC#code
    3 years: 40% annual yield (total 120% of stake given as reward at maturation)
    https://testnet.bscscan.com/address/0x74Cda84486232aef701e3656D12a3fC42E7064ef#code
    Iâ€™ve also deployed a 1 day, 10% reward version for ease of testing:
    https://testnet.bscscan.com/address/0xCbF132A8Bc6bF64C22CC3004DD7d20bbEb127dA7#code
    All have the standard 20% initial burn rate, decreasing linearly.
  */
  let Chain = {
    local: {
      chainId: "0x539",
      chainName: "Localhost 8545",
      nativeCurrency: {
        name: "Local Ether",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["http://localhost:8545"],
      tokenContractAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
      stakingContractAddress: selectedContract,
    },
    bsctest: {
      chainId: "0x61",
      chainName: "Binance Smart Chain Testnet",
      nativeCurrency: {
        name: "Binance Coin",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
      blockExplorerUrls: ["https://testnet.bscscan.com"],
      // These are the addresses on the blockchain that the contracts reside at
      tokenContractAddress: "0xf2B7Ff29F36cD0b170BD0903d99dFEc518fbE94f",
      stakingContractAddress: selectedContract,
    },
    bsc: {
      chainId: "0x38",
      chainName: "Binance Smart Chain",
      nativeCurrency: {
        name: "Binance Coin",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      blockExplorerUrls: ["https://bscscan.com"],
    },
  };
  let selectedChain = Chain.bsctest;
  // Set these for easy viewing
  // document.getElementById("tokenContract").innerText =
  //   selectedChain.tokenContractAddress;
  if (tokenContract_ref.current) {
    tokenContract_ref.current.innerText = selectedChain.tokenContractAddress;
    stakingContract_ref.current.innerText =
      selectedChain.stakingContractAddress;
  }
  // document.getElementById("stakingContract").innerText =
  //   selectedChain.stakingContractAddress;

  const stakingAbi = [
    {
      inputs: [
        {
          internalType: "address",
          name: "token_",
          type: "address",
        },
        {
          internalType: "uint32",
          name: "maturationDays_",
          type: "uint32",
        },
        {
          internalType: "uint32",
          name: "startingBurnRate_",
          type: "uint32",
        },
        {
          internalType: "uint32",
          name: "rewardRate_",
          type: "uint32",
        },
        {
          internalType: "uint32",
          name: "stakingFeeRate_",
          type: "uint32",
        },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "staker",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "stakeIndex",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "RewardClaimed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "staker",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "stakeIndex",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "feesCharged",
          type: "uint256",
        },
      ],
      name: "StakeDeposited",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "staker",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "stakeIndex",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "stakeRefunded",
          type: "uint256",
        },
      ],
      name: "StakeRefunded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "staker",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "stakeIndex",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "stakeWithdrawn",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "stakeBurnt",
          type: "uint256",
        },
      ],
      name: "StakeWithdrawn",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "claimReward",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "deploymentDate",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "depositStake",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "getInvestor",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getInvestorCount",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "investor",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "getInvestorStake",
      outputs: [
        {
          components: [
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "reward",
              type: "uint256",
            },
            {
              internalType: "address",
              name: "staker",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "startDate",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "dateWithdrawn",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "totalRewardClaimed",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "stakeBurnt",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "stakingFee",
              type: "uint256",
            },
          ],
          internalType: "struct Staking.Stake",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "investor",
          type: "address",
        },
      ],
      name: "getInvestorStakeCount",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "getStake",
      outputs: [
        {
          components: [
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "reward",
              type: "uint256",
            },
            {
              internalType: "address",
              name: "staker",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "startDate",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "dateWithdrawn",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "totalRewardClaimed",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "stakeBurnt",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "stakingFee",
              type: "uint256",
            },
          ],
          internalType: "struct Staking.Stake",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getStakeCount",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getTimeRemaining",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "maturationDate",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "maturationPeriod",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "investor",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "previewInvestorClaimReward",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "investor",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "previewInvestorWithdrawStake",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "investor",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "refundInvestorStake",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "rewardRate",
      outputs: [
        {
          internalType: "uint32",
          name: "",
          type: "uint32",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "stakingFeeRate",
      outputs: [
        {
          internalType: "uint32",
          name: "",
          type: "uint32",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "startingBurnRate",
      outputs: [
        {
          internalType: "uint32",
          name: "",
          type: "uint32",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "token",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "index",
          type: "uint256",
        },
      ],
      name: "withdrawStake",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];

  const tokenAbi = [
    {
      inputs: [
        {
          internalType: "string",
          name: "name_",
          type: "string",
        },
        {
          internalType: "string",
          name: "symbol_",
          type: "string",
        },
        {
          internalType: "uint8",
          name: "decimals_",
          type: "uint8",
        },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
      ],
      name: "allowance",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "approve",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "burn",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "decimals",
      outputs: [
        {
          internalType: "uint8",
          name: "",
          type: "uint8",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "subtractedValue",
          type: "uint256",
        },
      ],
      name: "decreaseAllowance",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "addedValue",
          type: "uint256",
        },
      ],
      name: "increaseAllowance",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "mint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "transfer",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "transferFrom",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];

  let signerAddress = ""; // Address of the user

  async function setup() {
    await connectToChain();
    await connectToContracts();
    showSignerAddress();
  }

  async function connectToChain() {
    // For more information about using metamask with web3, see: https://docs.metamask.io/guide/
    const metamaskProvider = await detectEthereumProvider();
    await metamaskProvider.request({ method: "eth_requestAccounts" });
    // Check the chain ID to ensure we are on the correct network
    const chainId = await metamaskProvider.request({ method: "eth_chainId" });
    // If we are not on bsc testnet, switch to it
    // This is a good resource for chain IDs: https://chainlist.org/
    if (chainId !== selectedChain.chainId) {
      try {
        await metamaskProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: selectedChain.chainId }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            // Add chain to metamask
            await metamaskProvider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: selectedChain.chainId,
                  chainName: selectedChain.chainName,
                  rpcUrls: selectedChain.rpcUrls, // ['https://bsctestapi.terminet.io/rpc'],
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    }
    // Instantiate the ethers provider and signer
    const ethersProvider = new ethers.providers.Web3Provider(metamaskProvider);
    signer = ethersProvider.getSigner();
    signerAddress = await signer.getAddress();
  }

  function connectToContracts() {
    if (!selectedChain.tokenContractAddress) {
      throw new Error("Token contract address not set");
    }
    tokenContract = new ethers.Contract(
      selectedChain.tokenContractAddress,
      tokenAbi,
      signer
    );
    if (!selectedChain.stakingContractAddress) {
      throw new Error("Staking contract address not set");
    }
    stakingContract = new ethers.Contract(
      selectedChain.stakingContractAddress,
      stakingAbi,
      signer
    );
  }

  function showSignerAddress() {
    // document.getElementById("signerAddress").innerText = signerAddress;
    signerAddress_ref.current.innerText = signerAddress;
  }

  async function checkAllowance(amount) {
    if (!tokenContract) {
      throw new Error("Token contract not connected");
    }
    const weiAmount = tokenAmountToWeiAmount(amount);
    // Get the token allowance to see if the staking contract is approved to spend our tokens
    const userAllowance = await tokenContract.allowance(
      signerAddress,
      selectedChain.stakingContractAddress
    );
    // If the allowance is less than the amount we want to pay, we need to approve the invoice contract to spend our tokens
    if (userAllowance.lt(weiAmount)) {
      await // We use MaxUint256 because this is the highest value you can use in an approve transaction
      // This reduces the gas cost by a user as often this is the highest value they will ever need
      (
        await tokenContract.approve(
          selectedChain.stakingContractAddress,
          ethers.constants.MaxUint256
        )
      ).wait();
    }
  }

  async function depositStake() {
    await setup();

    const amount = depositStakeAmount_ref.current.value;
    await checkAllowance(amount);

    // Convert the amount from BTAF token to wei
    // 1 BTAF token = 10^18 wei
    const weiAmount = tokenAmountToWeiAmount(amount);

    // Call the depositStake function on the staking contract
    const tx = await stakingContract.depositStake(weiAmount);
    await tx.wait();
    console.log("Stake deposited");
  }

  async function getInvestorStakeCount() {
    const investorAddress = getInvestorStakeCountAddress_ref.current.value;
    await setup();

    // Call the listStakes function on the staking contract
    const count = await stakingContract.getInvestorStakeCount(investorAddress);

    console.log("Stake count:", count.toNumber());
    // document.getElementById(
    //   "stakeCountA"
    // ).innerText = `Stake count: ${count.toNumber()}`;
    stakeCountA_ref.current.innerText = `Stake count: ${count.toNumber()}`;
  }

  async function getInvestorCount() {
    await setup();

    // Call the listStakes function on the staking contract
    const count = await stakingContract.getInvestorCount();

    console.log("Investor count:", count.toNumber());

    // document.getElementById(
    //   "investorCount"
    // ).innerText = `Investor count: ${count.toNumber()}`;
    investorCount_ref.current.innerText = `Investor count: ${count.toNumber()}`;
  }

  async function getInvestorStake() {
    // const idx = document.getElementById("getInvestorStakeStakeId").value;
    const idx = getInvestorStakeStakeId_ref.current.value;
    // const addressx = document.getElementById(
    //   "getInvestorStakeStakeAddress"
    // ).value;
    const addressx = getInvestorStakeStakeAddress_ref.current.value;
    await setup();

    // Call the listStakes function on the staking contract
    const stakeInfo = await stakingContract.getInvestorStake(addressx, idx);

    console.log("Stake ID", idx);
    console.log("Staker", stakeInfo.staker);
    console.log("Amount", weiAmountToTokenAmount(stakeInfo.amount));
    console.log(
      "Start date",
      new Date(stakeInfo.startDate.toNumber() * 1000).toString()
    );
    console.log(
      "Rewards claimed",
      weiAmountToTokenAmount(stakeInfo.totalRewardClaimed)
    );

    // document.getElementById(
    //   "stakerA"
    // ).innerText = `Staker: ${stakeInfo.staker}`;
    // document.getElementById(
    //   "amountA"
    // ).innerText = `Amount: ${weiAmountToTokenAmount(stakeInfo.amount)}`;
    // document.getElementById("startDateA").innerText = `Start date: ${new Date(
    //   stakeInfo.startDate.toNumber() * 1000
    // ).toString()}`;
    // document.getElementById(
    //   "stakeBurntA"
    // ).innerText = `Stake burnt: ${weiAmountToTokenAmount(
    //   stakeInfo.stakeBurnt
    // ).toString()}`;
    // document.getElementById(
    //   "stakingFeeA"
    // ).innerText = `Staking fee: ${weiAmountToTokenAmount(
    //   stakeInfo.stakingFee
    // )}`;
    // document.getElementById(
    //   "rewardA"
    // ).innerText = `Total reward at maturation: ${weiAmountToTokenAmount(
    //   stakeInfo.reward
    // )}`;
    // document.getElementById(
    //   "rewardsClaimedA"
    // ).innerText = `Rewards claimed: ${weiAmountToTokenAmount(
    //   stakeInfo.totalRewardClaimed
    // )}`;

    const dateWithdrawn = stakeInfo.dateWithdrawn.toNumber();
    if (dateWithdrawn) {
      console.log("Withdrawn", Date(dateWithdrawn * 1000).toString());
    } else {
      console.log("Stake not yet withdrawn");
    }
  }

  async function previewInvestorClaimReward() {
    // const idx = document.getElementById("previewInvestorClaimRewardId").value;
    // const addressx = document.getElementById(
    //   "previewInvestorClaimRewardAddress"
    // ).value;
    const idx = previewInvestorClaimRewardId_ref.current.value;
    const addressx = previewInvestorClaimRewardAddress_ref.current.value;
    await setup();

    // Call the listStakes function on the staking contract
    const rewardPreview = await stakingContract.previewInvestorClaimReward(
      addressx,
      idx
    );

    console.log("Stake ID", idx);
    console.log("Staker", addressx);
    // document.getElementById(
    //   "rewardsPreview"
    // ).innerText = `Rewards preview: ${weiAmountToTokenAmount(rewardPreview)}`;
    rewardsPreview_ref.current.innerText = `Rewards preview: ${weiAmountToTokenAmount(
      rewardPreview
    )}`;
  }

  async function previewInvestorWithdrawStake() {
    // const idx = document.getElementById(
    //   "previewInvestorWithdrawRewardId"
    // ).value;
    const idx = previewInvestorWithdrawRewardId_ref.current.value;
    // const addressx = document.getElementById(
    //   "previewInvestorWithdrawRewardAddress"
    // ).value;
    const addressx = previewInvestorWithdrawRewardAddress_ref.current.value;
    console.log(idx, addressx);
    await setup();

    // Call the listStakes function on the staking contract
    const withdrawPreview = await stakingContract.previewInvestorWithdrawStake(
      addressx,
      idx
    );
    console.log(withdrawPreview);
    const withdrawAmount = withdrawPreview[0];
    const burnAmount = withdrawPreview[1];

    console.log("Stake ID", idx);
    console.log("Staker", addressx);
    console.log("Withdraw amount", weiAmountToTokenAmount(withdrawAmount));
    console.log("Burn amount", weiAmountToTokenAmount(burnAmount));
    // document.getElementById(
    //   "withdrawPreview"
    // ).innerText = `Withdraw preview amount: ${weiAmountToTokenAmount(
    //   withdrawAmount
    // )}`;
    withdrawPreview_ref.current.innerText = `Withdraw preview amount: ${weiAmountToTokenAmount(
      withdrawAmount
    )}`;
    burnPreview_ref.current.innerText = `Burn preview: ${weiAmountToTokenAmount(
      burnAmount
    )}`;
    // document.getElementById(
    //   "burnPreview"
    // ).innerText = `Burn preview: ${weiAmountToTokenAmount(burnAmount)}`;
  }

  async function getInvestorAddress() {
    // const idx = document.getElementById("investorIndex").value;

    const idx = investorIndex_ref.current.value;

    await setup();
    const investorAddress = await stakingContract.getInvestor(idx);
    console.log("Investor address", investorAddress);
    // document.getElementById(
    //   "investor"
    // ).innerText = `Investor address: ${investorAddress}`;
    investor_ref.current.innerText = `Investor address: ${investorAddress}`;
  }

  async function claimReward() {
    // const idx = document.getElementById("claimRewardStakeId").value;
    const idx = claimRewardStakeId_ref.current.value;

    await setup();

    const startingBalance = await tokenContract.balanceOf(signerAddress);

    // Call the claimReward function on the staking contract
    const tx = await stakingContract.claimReward(idx);
    await tx.wait();
    const newBalance = await tokenContract.balanceOf(signerAddress);
    const claim = weiAmountToTokenAmount(newBalance.sub(startingBalance));
    console.log(`${claim} reward claimed`);

    rewardClaimed_ref.current.innerText = `${claim} reward claimed`;
    // document.getElementById(
    //   "rewardClaimed"
    // ).innerText = `${claim} reward claimed`;
  }

  async function withdrawStake() {
    // const idx = document.getElementById("withdrawStakeStakeId").value;
    const idx = withdrawStakeStakeId_ref.current.value;

    await setup();

    const stake = await stakingContract.getStake(idx);
    const startingBalance = await tokenContract.balanceOf(signerAddress);

    // Call the withdrawStake function on the staking contract
    const tx = await stakingContract.withdrawStake(idx);
    await tx.wait();
    const newBalance = await tokenContract.balanceOf(signerAddress);
    const withdrawn = weiAmountToTokenAmount(newBalance.sub(startingBalance));
    // Note the use of BigNumber here rather than then one provided by ethers
    // This is because the BigNumber provided by ethers only takes integers
    const burnt = new BigNumber(stake.amount.toString()).minus(withdrawn);
    console.log(
      `${withdrawn} withdrawn (${weiAmountToTokenAmount(
        burnt.toString()
      )} burnt)`
    );
    withdrawnOutput_ref.current.innerText = `${withdrawn} withdrawn (${burnt.toString()} burnt`;
    // document.getElementById(
    //   "withdrawnOutput"
    // ).innerText = `${withdrawn} withdrawn (${burnt.toString()} burnt`;
  }

  function tokenAmountToWeiAmount(tokenAmount) {
    // The function below is just a nice utility to convert from eth to wei
    // It just does tokenAmount * 10 ** 18;
    // For a more visual tool for unit conversions, see: https://crypto-convert.vercel.app/
    // For more information about ERC20 token denominations, see:
    // https://ethereum.stackexchange.com/questions/20811/what-denomination-should-i-issue-my-erc20-token-by
    return ethers.utils.parseUnits(tokenAmount, 18);
  }

  function weiAmountToTokenAmount(wei) {
    // The function below is just a nice utility to convert from wei to eth
    // It just does wei / 10 ** 18;
    // For a more visual tool for unit conversions, see: https://crypto-convert.vercel.app/
    // For more information about ERC20 token denominations, see:
    // https://ethereum.stackexchange.com/questions/20811/what-denomination-should-i-issue-my-erc20-token-by
    return ethers.utils.formatUnits(wei, 18);
  }

  async function getTimeRemaining() {
    await setup();
    const timeRemaining = await stakingContract.getTimeRemaining();
    console.log("Time remaining", timeRemaining.toString());
    const secondsRemaining = timeRemaining.toNumber();
    // Convert to days, hours, minutes, seconds
    const days = Math.floor(secondsRemaining / (3600 * 24));
    const hours = Math.floor((secondsRemaining % (3600 * 24)) / 3600);
    const minutes = Math.floor((secondsRemaining % 3600) / 60);
    const seconds = Math.floor(secondsRemaining % 60);

    // document.getElementById(
    //   "timeRemaining"
    // ).innerText = `Time remaining: ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    timeRemaining_ref.current.innerText = `Time remaining: ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  }

  return (
    <>
      <Box className="container" style={{ width: "60%" }}>
        <Box mt={2} mb={2}>
          <Typography
            variant="h5"
            style={{ color: "#084298", fontWeight: "600" }}
          >
            Staking
          </Typography>
        </Box>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "600" }}>
            Your address
          </Typography>
          <Typography
            variant="caption"
            id="signerAddress"
            ref={signerAddress_ref}
            style={{ color: "#6c757d" }}
          >
            {signerAddress ? signerAddress : "(not connected)"}
          </Typography>

          <Typography variant="h6" style={{ fontWeight: "600" }}>
            Token Contract
          </Typography>
          {/* <p id="tokenContract">{Chain.bsctest}</p> */}
          <Typography
            variant="caption"
            id="tokenContract"
            ref={tokenContract_ref}
            style={{ color: "#6c757d" }}
          ></Typography>

          <Typography variant="h6" style={{ fontWeight: "600" }}>
            Staking Contract
          </Typography>
          <Typography
            variant="caption"
            id="stakingContract"
            ref={stakingContract_ref}
            style={{ color: "#6c757d" }}
          >
            {selectedChain.stakingContractAddress}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              sx={{ borderRadius: "1rem", textTransform: "capitalize" }}
              variant="contained"
              size="small"
              onClick={setup}
              endIcon={<Icon icon="ri:loader-4-line" />}
              color="success"
            >
              Connect
            </Button>
          </Box>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            How much time is left before maturation?
          </Typography>
          <Typography
            variant="caption"
            id="timeRemaining"
            ref={timeRemaining_ref}
          ></Typography>
          <Box sx={{ mt: 1 }}>
            <Button
              sx={{ borderRadius: "1rem", textTransform: "capitalize" }}
              variant="outlined"
              size="small"
              onClick={getTimeRemaining}
            >
              Time left
            </Button>
          </Box>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            Deposit a stake to the contract
          </Typography>

          <Box sx={{ mb: 2, mt: 2 }}>
            <TextField
              id="depositStakeAmount"
              inputRef={depositStakeAmount_ref}
              label="Amount"
              size="small"
              style={{ borderRadius: "8px" }}
            />
          </Box>

          <Button
            sx={{ borderRadius: "1rem", textTransform: "capitalize" }}
            variant="outlined"
            onClick={depositStake}
          >
            Deposit Stake
          </Button>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            Get the number of stakes a given investor address has
          </Typography>
          <Box sx={{ mb: 2, mt: 2 }}>
            <TextField
              id="getInvestorStakeCountAddress"
              inputRef={getInvestorStakeCountAddress_ref}
              label="0x000..."
              type="text"
              size="small"
            />
          </Box>
          <Button
            sx={{ borderRadius: "1rem", textTransform: "capitalize" }}
            variant="outlined"
            onClick={getInvestorStakeCount}
          >
            Get number of stakes
          </Button>
          <Typography
            variant="caption"
            id="stakeCountA"
            ref={stakeCountA_ref}
          ></Typography>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            Return current information on an investor's stake
          </Typography>
          <Box
            sx={{
              display: "grid",
              rowGap: 1,
              columnGap: 2,
              marginTop: 2,
              gridTemplateColumns: {
                xs: "repeat(3, 1fr)",
                sm: "repeat(3, 1fr)",
              },
            }}
          >
            <TextField
              id="getInvestorStakeStakeId"
              inputRef={getInvestorStakeStakeId_ref}
              label="stake index"
              type="text"
              size="small"
            />
            <TextField
              id="getInvestorStakeStakeAddress"
              inputRef={getInvestorStakeStakeAddress_ref}
              label="0x000..."
              type="text"
              size="small"
            />
            <Typography variant="caption" id="stakerA"></Typography>
            <Typography id="amountA"></Typography>
            <Typography variant="caption" id="startDateA"></Typography>
            <Typography variant="caption" id="stakeBurntA"></Typography>
            <Typography variant="caption" id="rewardA"></Typography>
            <Typography variant="caption" id="rewardsClaimedA"></Typography>
          </Box>
          <Button
            sx={{ borderRadius: "1rem", textTransform: "capitalize" }}
            variant="outlined"
            onClick={getInvestorStake}
          >
            Get stake info
          </Button>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            Get the number of wallets that have staked
          </Typography>
          <Typography
            variant="caption"
            id="investorCount"
            ref={investorCount_ref}
          ></Typography>
          <Box>
            <Button
              sx={{ borderRadius: "1rem", textTransform: "capitalize", mt: 1 }}
              variant="outlined"
              onClick={getInvestorCount}
            >
              Get number of investors
            </Button>
          </Box>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            Get the address of an investor by index
          </Typography>

          <Box sx={{ mb: 2, mt: 2 }}>
            <TextField
              id="investorIndex"
              inputRef={investorIndex_ref}
              label="investor index"
              type="text"
              size="small"
            />
          </Box>

          <Typography
            variant="caption"
            id="investor"
            ref={investor_ref}
          ></Typography>
          <Button
            sx={{ borderRadius: "1rem", textTransform: "capitalize" }}
            variant="outlined"
            onClick={getInvestorAddress}
          >
            Get investor address
          </Button>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            Return a preview of the rewards that would be due if an investor
            claimed them now
          </Typography>
          <Box
            sx={{
              display: "grid",
              rowGap: 1,
              columnGap: 2,
              marginTop: 2,
              gridTemplateColumns: {
                xs: "repeat(3, 1fr)",
                sm: "repeat(3, 1fr)",
              },
            }}
          >
            <TextField
              id="previewInvestorClaimRewardId"
              inputRef={previewInvestorClaimRewardId_ref}
              label="stake index"
              type="text"
              size="small"
            />
            <TextField
              id="previewInvestorClaimRewardAddress"
              inputRef={previewInvestorClaimRewardAddress_ref}
              label="0x000..."
              type="text"
              size="small"
            />
          </Box>

          <Typography
            variant="caption"
            id="rewardsPreview"
            ref={rewardsPreview_ref}
          ></Typography>
          <Button
            sx={{ borderRadius: "1rem", textTransform: "capitalize", mt: 2 }}
            variant="outlined"
            onClick={previewInvestorClaimReward}
          >
            Rewards Preview info
          </Button>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            Claim any rewards that due to you for a given stake
          </Typography>

          <Box
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <TextField
              id="claimRewardStakeId"
              inputRef={claimRewardStakeId_ref}
              label="stake index"
              type="text"
              size="small"
            />
          </Box>

          <Typography
            variant="caption"
            id="rewardClaimed"
            ref={rewardClaimed_ref}
          ></Typography>
          <Button
            sx={{ borderRadius: "1rem", textTransform: "capitalize" }}
            variant="outlined"
            onClick={claimReward}
          >
            Claim rewards
          </Button>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            Return a the amount that would be withdrawn and burnt if an investor
            unstaked now
          </Typography>
          <Box
            sx={{
              display: "grid",
              rowGap: 1,
              columnGap: 2,
              marginTop: 2,
              gridTemplateColumns: {
                xs: "repeat(3, 1fr)",
                sm: "repeat(3, 1fr)",
              },
            }}
          >
            <TextField
              id="previewInvestorWithdrawRewardId"
              inputRef={previewInvestorWithdrawRewardId_ref}
              label="stake index"
              type="text"
              size="small"
            />
            <TextField
              id="previewInvestorWithdrawRewardAddress"
              inputRef={previewInvestorWithdrawRewardAddress_ref}
              label="0x000..."
              type="text"
              size="small"
            />
          </Box>
          <Typography
            variant="caption"
            id="withdrawPreview"
            ref={withdrawPreview_ref}
          ></Typography>
          <Typography
            variant="caption"
            id="burnPreview"
            ref={burnPreview_ref}
          ></Typography>
          <Button
            sx={{ borderRadius: "1rem", textTransform: "capitalize", mt: 2 }}
            variant="outlined"
            onClick={previewInvestorWithdrawStake}
          >
            Withdrawal Preview
          </Button>
        </Card>

        <Card
          sx={{
            p: 2,
            mb: 2,
            borderRadius: "0.6rem",
          }}
        >
          <Typography variant="subtile2" style={{ fontWeight: "500" }}>
            Withdraw your stake by index number
          </Typography>
          <Box
            sx={{
              display: "grid",
              rowGap: 1,
              columnGap: 2,
              marginTop: 2,
              gridTemplateColumns: {
                xs: "repeat(3, 1fr)",
                sm: "repeat(3, 1fr)",
              },
            }}
          >
            <TextField
              id="withdrawStakeStakeId"
              inputRef={withdrawStakeStakeId_ref}
              label="0"
              type="text"
              size="small"
            />
          </Box>

          <Typography
            variant="caption"
            id="withdrawnOutput"
            ref={withdrawnOutput_ref}
          ></Typography>

          <Button
            sx={{ borderRadius: "1rem", textTransform: "capitalize", mt: 2 }}
            variant="outlined"
            onClick={withdrawStake}
          >
            Withdraw stake
          </Button>
        </Card>
      </Box>
    </>
  );
}

export default App;
